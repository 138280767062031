import { useState, useEffect } from 'react';
// @mui
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
// components
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import ClientService from 'src/services/client';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export default function KYCSnackbar() {
  const router = useRouter();
  const [openAlertKYC, setOpenAlertKYC] = useState(false);


  return (
    <Snackbar
      open={openAlertKYC}
      onClose={() => setOpenAlertKYC(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        severity="warning"
        color="primary"
        sx={{ width: 1 }}
        action={
          <>
            {' '}
            <Button
              onClick={() => setOpenAlertKYC(false)}
              color="primary"
              size="small"
              variant="outlined"
              sx={{
                mr: 1,
              }}
            >
              Más tarde
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => router.push(paths.clients.kyc)}
              sx={{
                bgcolor: 'primary.dark',
              }}
            >
              Responder
            </Button>
          </>
        }
      >
        Recuerda responder el cuestionario
      </Alert>
    </Snackbar>
  );
}
