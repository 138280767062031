import numeral from 'numeral';
import 'numeral/locales/es';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fNumber2(number) {
  if (!number) return ''; // Manejar valores vacíos
  numeral.locale('es'); // Configura el idioma español
  return numeral(number).format('0,0'); // Formato con separadores españoles
}

export function fCurrency(number) {
  const format =
    number != null
      ? `${Number(number).toLocaleString('it-IT', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}`
      : '-';

  return format;
  // return result(format, '.00');
}

export function fCurrencyInput(number) {
  if (!number) {
    return '';
  }
  numeral.locale('es');
  const formattedNumber = numeral(number).format();

  return formattedNumber;
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fPercent100(number) {
  const format = number ? numeral(Number(number)).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fColor100(number) {
  if (number <= 30) {
    return 'red';
  }
  if (number > 30 && number < 60) {
    return 'orange';
  }
  if (number >= 60) {
    return 'green';
  }
  return 'gray';
}

export function fFixedTwoDecimals(number) {
  // eslint-disable-next-line no-restricted-globals
  if (number == null || isNaN(number)) return '-'; // Maneja valores no válidos
  return Number(number).toFixed(2); // Limita a exactamente 2 decimales
}

export function fNumberCustom(number) {
  // Convierte siempre a número para asegurar compatibilidad
  const parsedNumber = Number(number);

  // eslint-disable-next-line no-restricted-globals
  if (parsedNumber == null || isNaN(parsedNumber)) return '-'; // Manejo de valores no válidos

  // Si es un entero, usa el separador de miles
  if (Number.isInteger(parsedNumber)) {
    return parsedNumber.toLocaleString('es-ES'); // Separadores de miles con .
  }

  // Si no es entero, muestra separadores de miles y decimales
  return parsedNumber.toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
