import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { io } from 'socket.io-client';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { ROLES } from 'src/_mock/_roles';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  // connects with the socket and sets notifications for new documents
  const connectSocket = useCallback(
    (email, role) => {
      if (socket) {
        socket.disconnect();
      }
      const namespace = role === 'agent' || role === 'admin' ? 'agents' : 'clients';
      const newSocket = io(`${process.env.REACT_APP_URL_SOCKET}${namespace}`);
      setSocket(newSocket);

      newSocket.on('connect', () => {
        newSocket.emit('user_conected', email);
      });
      
    }, // dont add socket to the dependencies below
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router, enqueueSnackbar]
  );

  // disconects the socket
  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [socket]);

  // to re-render when the client cancels a recurrent payment
  const reRenderPaymentsComponent = useCallback(
    (setUpdate, update) => {
      if (socket && !socket.hasListeners('update_pay')) {
        socket.on('update_pay', () => {
          setUpdate(!update);
        });
      }
    },
    [socket]
  );

  const value = useMemo(
    () => ({
      socket,
      connectSocket,
      disconnectSocket,
      reRenderPaymentsComponent,
    }),
    [
      socket,
      connectSocket,
      disconnectSocket,
      reRenderPaymentsComponent,
    ]
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.node,
};

// down here are the definitions of buttons used

const dataButton = (targetPath, router) => (
  <Button
    type="button"
    onClick={() => {
      if (targetPath) {
        if (window.location.pathname === targetPath) {
          window.location.reload();
        } else {
          router.push(targetPath);
        }
      }
    }}
  >
    <p style={{ color: 'white', margin: '0px', padding: '0px' }}>Ver</p>
  </Button>
);

const messageButton = (data, router, showBadge, setOpen) => (
  <Button
    type="button"
    onClick={() => {
      switch (data.role) {
        case ROLES.AGENT:
          if (window.location.pathname === paths.agents.client.select(data.id)) {
            window.location.reload();
          } else {
            router.push(paths.agents.client.select(data.id));
          }
          break;
        case ROLES.CLIENT:
          showBadge(false);
          setOpen(true);
          break;
        default:
          break;
      }
    }}
  >
    <p style={{ color: 'white', margin: '0px', padding: '0px' }}>Ver</p>
  </Button>
);

const questionaryButton = (data, router) => (
  <Button
    type="button"
    onClick={() => {
      if (window.location.pathname === paths.agents.client.select(data.id)) {
        window.location.reload();
      } else {
        router.push(paths.agents.client.select(data.id));
      }
    }}
  >
    <p style={{ color: 'white', margin: '0px', padding: '0px' }}>Ver</p>
  </Button>
);

const intentButton = (content, router) => (
  <Button
    type="button"
    onClick={() => {
      if (window.location.pathname === paths.agents.client.select(content.id)) {
        window.location.reload();
      } else {
        router.push(paths.agents.client.select(content.id));
      }
    }}
  >
    <p style={{ color: 'white', margin: '0px', padding: '0px' }}>Ver</p>
  </Button>
);
