import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

// ----------------------------------------------------------------------

export function errorAlert(title, message, setErrorMsg, setErrorTitle, setOpenDialog) {
    setErrorMsg(message);
    setErrorTitle(title);
    setOpenDialog(true);
}

const reload = () => {
    /* eslint-disable no-restricted-globals */
    location.reload();
};

// ----------------------------------------------------------------------

export default function ErrorView({ setOpenDialog, openDialog, errorMsg, errorTitle, closeFunction = reload }) {
    if (closeFunction === null) {
        closeFunction = reload;
    }

    return (
        <Dialog
            open={openDialog}
            onClose={(event) => {
                setOpenDialog(false);
                /* eslint-disable no-restricted-globals */
                closeFunction();
            }}
            style={{ justifyContent: 'center' }}
        >
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText align="center">
                    {errorMsg}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    /* eslint-disable no-restricted-globals */
                    onClick={closeFunction}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ErrorView.propTypes = {
    setOpenDialog: PropTypes.func,
    openDialog: PropTypes.bool,
    errorMsg: PropTypes.string,
    errorTitle: PropTypes.string,
    closeFunction: PropTypes.func,
};
