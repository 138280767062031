import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Mi perfil',
    linkTo: paths.clients.profile,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();
  const pathname = window.location.href;

  const { user, logout } = useAuthContext();
  // const user = {
  //   role: 'client',
  //   displayName: 'David Alcalde',
  //   email: 'davidalc2012@gmail.com',
  // };
  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  const isClientLanding = router.pathname.includes('/clients/landing');

  const adminOptions = [
    { label: 'Vista administrativa', path: paths.console.admin },
    { label: 'Vista de solicitudes', path: paths.admins.root },
  ];

  const excludedRoutes = [
    paths.console.indicatorsBuilder,
    paths.console.admin,
    paths.console.businessRules,
    paths.console.logs,
  ];

  const filteredOptions = adminOptions.filter((option) => {
    if (option.label === 'Vista administrativa') {
      // Excluir "Vista administrativa" si estamos en una ruta de ADMIN CONSOLE
      return !excludedRoutes.includes(router.pathname);
    }
    if (option.label === 'Vista de solicitudes') {
      // Excluir "Vista de solicitudes" si estamos en /admins, /agents o /analysts
      const restrictedPaths = [paths.admins.root, paths.analyst.root, paths.agents.root];
      return !restrictedPaths.includes(router.pathname);
    }
    // Mostrar cualquier otra opción
    return true;
  });

  return (
    <>
      {user && (
        <>
          {!isClientLanding && (
            <>
              <Stack>
                <Typography variant="subtitle2">
                  {user.role === 'analyst' && 'Analista de crédito'}
                  {user.role === 'agent' && 'Agente comercial'}
                  {user.role === 'admin' && 'Administrador'}
                </Typography>
              </Stack>
              <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                  width: 40,
                  height: 40,
                  background: (theme) => alpha(theme.palette.grey[500], 0.08),
                  ...(popover.open && {
                    background: (theme) =>
                      `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                  }),
                }}
              >
                <Avatar
                  alt={user?.displayName}
                  sx={{
                    width: 36,
                    height: 36,
                    border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    background: (theme) =>
                      `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                  }}
                >
                  {user?.role === 'analyst' && 'A'}
                  {user?.role === 'admin' && 'A'}
                  {user?.role === 'agent' && 'A'}
                  {user?.role === 'client' && 'C'}
                </Avatar>
              </IconButton>
            </>
          )}

          <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
            <Box sx={{ p: 2, pb: 1.5 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {user?.role === 'client' && (
              <Stack sx={{ p: 1 }}>
                {OPTIONS.map((option) => (
                  <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                    {option.label}
                  </MenuItem>
                ))}
              </Stack>
            )}

            {user?.role === 'admin' &&
              filteredOptions.map((option) => (
                <MenuItem
                  key={option.label}
                  sx={{ m: 1 }}
                  onClick={() => {
                    router.push(option.path);
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem
              onClick={handleLogout}
              sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
            >
              Cerrar sesión
            </MenuItem>
          </CustomPopover>
        </>
      )}
    </>
  );
}
