import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import ErrorView, { errorAlert } from 'src/utils/error-dialog';
import { useAuthContext } from 'src/auth/hooks';
import { useRouter } from 'src/routes/hooks';
import ClientService from 'src/services/client';
import { getUuid } from 'src/utils/get-uuid';
// utils
import { icons, steps, stepLabels, stepsRejected, stepLabelsRejected } from 'src/utils/steps';

// componentes
import HorizontalTimeline from './app-horizontal-timeline';
import AppSnackbarComponent from './app-snackbar';
import { BannerLanding } from './banner-landing';
import ClientWelcome from './client-welcome';

// ----------------------------------------------------------------------

export default function OverviewAppView() {
  const theme = useTheme();

  const { user } = useAuthContext();
  const settings = useSettingsContext();
  // const { setBankAggregation, bankAggregation } = useBank();

  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [closeFunction, setCloseFunction] = useState();

  const [bankAggregationDialog, setBankAggregationDialog] = useState(false);

  const [bankLoading, setBankLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(1);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signedForm, setSignedForm] = useState(false);
  const [documentStatus, setDocumentStatus] = useState();
  const [rejectedDocuments, setRejectedDocuments] = useState();

  const uuid = getUuid();

  useEffect(() => {
    const fetchData = async () => {
      if (activeStep !== 6) {
        try {
          const response = await ClientService.get(uuid);
          setClient(response);
          localStorage.setItem('user', JSON.stringify({ email: response.email, role: 'client' }));

          if (response?.expedient?.documents.length > 0) {
            const sortedDocuments = response.expedient?.documents.sort(
              (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt)
            );
            console.log('Sorted documents:', sortedDocuments);
            const relevantDocuments = [];
            const documentMap = new Map();

            sortedDocuments.forEach((document) => {
              if (!documentMap.has(document.name)) {
                documentMap.set(document.name, document);
                relevantDocuments.push(document);
              }
            });

            const allApproved = relevantDocuments.every((document) => document.status === 3);
            const allReviewing = relevantDocuments.every((document) => document.status === 1);
            const hasDenied = relevantDocuments.some((document) => document.status === 2);
            const hasPending = relevantDocuments.some((document) => document.status === 0);

            if (allApproved) {
              setDocumentStatus('approved');
            } else if (allReviewing) {
              setDocumentStatus('reviewing');
            } else if (hasDenied) {
              setDocumentStatus('denied');
            } else if (hasPending) {
              setDocumentStatus('pending');
            } else {
              setDocumentStatus('reviewing');
            }

            console.log('Document status:', documentStatus);
            setRejectedDocuments(relevantDocuments.filter((doc) => doc.status === 2));
          }

          let step = 0;

          if (response?.process === 'none') {
            step = 1;
          }

          if (
            (response?.process === 'questionnaire' || response?.process === 'bank') &&
            response?.recomendation !== 'ACEPTAR'
          ) {
            console.log('Se setea el paso 2');
            step = 2;
          }
          console.log('Response', response);
          if (
            (response?.process === 'questionnaire' || response?.process === 'bank') &&
            (response?.recomendation === 'ACEPTAR' || response?.state === 'preapproved') &&
            response?.recomendation !== 'DENEGAR'
          ) {
            console.log('Se setea el paso 3');

            step = 3;
          }

          console.log('Test documentStatus:', documentStatus);
          if (step === 3 && documentStatus === 'reviewing') {
            console.log('Se setea el paso 4', step, documentStatus);
            step = 4;
          }

          if (response?.expedient?.documents && response?.expedient?.documents.length > 0) {
            if (documentStatus === 'approved') {
              console.log('Se setea el paso 5', step, documentStatus);
              step = 5;
            }
          }

          if (response?.state && response?.state === 'approved') {
            console.log('Se setea el paso 6', step, documentStatus);
            step = 5;
          }

          if (response?.state === 'signed') {
            step = 6;
          }

          setActiveStep(step);
        } catch (error) {
          errorAlert(
            'Ocurrio un error inesperado',
            'Ha ocurrido un error inesperado al cargar la pagina',
            setErrorMsg,
            setErrorTitle,
            setOpenDialog
          );
          console.error('Error fetching client info:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [uuid, activeStep, documentStatus]);

  // eslint-disable-next-line no-shadow
  function getStepLabel(step, clientData, stepLabels) {
    const labels = stepLabels[step];
    if (typeof labels === 'string') {
      return labels;
    }

    switch (step) {
      case 2:
        if (clientData?.process) {
          return labels[clientData.process] || labels.default;
        }
        return labels.default;

      case 3:
        if (clientData?.process === 'questionnaire') {
          if (clientData?.recomendation === 'DENEGAR') {
            return labels?.questionnaire?.DENEGAR;
          }
          return labels.questionnaire?.default;
        }
        if (clientData?.process === 'bank') {
          return labels.bank;
        }
        return labels.default;

      case 4:
        if (clientData?.recomendation === 'DENEGAR') {
          return labels?.questionnaire?.DENEGAR;
        }
        if (clientData?.expedient?.documents) {
          return labels.documents;
        }
        return labels.default;
      case 5:
        if (clientData?.expedient?.documents) {
          return labels.documents;
        }
        return labels.default;
      case 6:
        if (signedForm) {
          return labels.documents;
        }
        return labels.default;

      default:
        return '';
    }
  }

  const currentStepLabel = getStepLabel(activeStep, client, stepLabels);

  console.log('Current:', client, activeStep);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <AppSnackbarComponent text="¡Recuerda completar tu acuerdo pendiente!" />
      {loading ? (
        <Backdrop sx={{ color: '#fffff', zIndex: () => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        client && (
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid container xs={12} md={12}>
              <Grid xs={12} md={10}>
                <Typography variant="h5" sx={{ whiteSpace: 'pre-line' }}>
                  ¡Bienvenido {client?.name}! Este es el estado de tu nueva financiación 👋
                </Typography>
              </Grid>
            </Grid>
            {/* Bolitas */}
            <Grid xs={12}>
              {client?.recomendation === 'DENEGAR' ? (
                <HorizontalTimeline
                  steps={stepsRejected}
                  clientData={client}
                  activeStep={2}
                  icons={icons}
                  stepLabels={stepLabelsRejected}
                  signedForm={signedForm}
                />
              ) : (
                <HorizontalTimeline
                  steps={steps}
                  clientData={client}
                  activeStep={activeStep}
                  icons={icons}
                  stepLabels={stepLabels}
                  signedForm={signedForm}
                />
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{ width: '100%' }}
            >
              <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <ClientWelcome
                  client={client}
                  stepLabels={stepLabels}
                  currentStep={activeStep}
                  currentLabel={activeStep !== 6 ? currentStepLabel : 'Operación firmada'}
                  img="src/assets/illustrations/characters/character_3"
                />
              </Grid>

              <Grid xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <BannerSelector
                  step={activeStep}
                  uuid={uuid}
                  setActiveStep={setActiveStep}
                  setSignedForm={setSignedForm}
                  recommendation={client?.recomendation}
                  state={client?.state}
                  client={client}
                  documentStatus={documentStatus}
                  rejectedDocuments={rejectedDocuments}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      )}
      <ErrorView
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        errorMsg={errorMsg}
        errorTitle={errorTitle}
        closeFunction={closeFunction}
      />
    </Container>
  );
}

function BannerSelector({
  step,
  uuid,
  setActiveStep,
  setSignedForm,
  recommendation,
  state,
  client,
  documentStatus,
  rejectedDocuments,
}) {
  const router = useRouter();

  let step_end;
  if (step === 2 && recommendation === 'REVISAR') {
    step_end = 7;
  } else if (
    step === 2 &&
    (state === 'preapproved' || state === 'review') &&
    recommendation !== 'DENEGAR'
  ) {
    step_end = 3;
  } else {
    step_end = step;
  }

  const aligns = [null, 'center', null, null, null, 'center'];

  const colors = [
    'linear-gradient(34deg, rgba(67, 185, 235, 0.8) 0%, rgba(146, 208, 235, 0.8) 29%, rgba(67, 185, 235, 0.8) 92%)', // Blue
    'linear-gradient(34deg, rgba(186, 80, 80,0.8) 0%, rgba(245, 88, 88,0.8) 29%, rgba(186, 80, 80,0.8) 92%)', // Red
    'linear-gradient(34deg, rgba(67, 185, 235, 0.8) 0%, rgba(146, 208, 235, 0.8) 29%, rgba(67, 185, 235, 0.8) 92%)', // Blue
    'linear-gradient(34deg, rgba(67, 185, 235, 0.8) 0%, rgba(146, 208, 235, 0.8) 29%, rgba(67, 185, 235, 0.8) 92%)', // Blue
    'linear-gradient(34deg, rgba(143, 204, 148, 0.8) 0%, rgba(179, 255, 186, 0.8) 29%, rgba(143, 204, 148, 0.8) 92%)', // Green
    'linear-gradient(34deg, rgba(143, 204, 148, 0.8) 0%, rgba(179, 255, 186, 0.8) 29%, rgba(143, 204, 148, 0.8) 92%)', // Green
    'linear-gradient(34deg, rgba(67, 185, 235, 0.8) 0%, rgba(146, 208, 235, 0.8) 29%, rgba(67, 185, 235, 0.8) 92%)', // Blue
  ];

  const textColors = [
    'rgba(19, 53, 235,0.6)',
    'rgba(98, 56, 56,0.7)',
    'rgba(19, 53, 235,0.6)',
    null,
    'rgba(106, 137, 108,0.7)',
    'rgba(106, 137, 108,0.7)',
    'rgba(19, 53, 235,0.6)',
  ];

  const headerTexts = [
    'Completa tu solicitud',
    'Tu solicitud fue rechazada',
    'Tu solicitud fue pre aprobada',
    'Se está revisando tu documentación',
    'Tu solicitud ha sido aprobada',
    '¡Todo listo!',
    'Estamos revisando tu solicitud',
  ];

  const texts = [
    'Puedes hacerlo a través de un cuestionario o brindándonos tu información bancaria',
    'Puedes intentarlo de nuevo acercándote con alguno de nuestros agentes',
    'Simplemente necesitaríamos que nos aportases una documentación adicional para poder continuar',
    'Estamos revisando la documentación aportada. Por favor, espere un poco más mientras es validada',
    'El último paso es firmar tu documentación y procederemos con la transferencia de los fondos',
    'La transferencia de fondos puede tardar de 24 a 48 horas',
    'Uno de nuestros analistas se encuentra revisando tu solicitud',
  ];

  const documentTexts = [
    'Estamos revisando la documentación aportada. Por favor, espere un poco más mientras es validada.',
    'Su documentación ha sido rechazada. Por favor, vuelva a enviar los documentos necesarios.',
  ];

  const documentHeaderTexts = ['Documentación en estudio', 'Documentación rechazada'];

  const buttons = [
    [
      { text: 'Agregación bancaria', onClick: () => router.push(`/clients/bank/${uuid}`) },
      { text: 'Cuestionario', onClick: () => router.push(`/clients/questionnaire/${uuid}`) },
    ],
    null,
    [{ text: 'Cargar documentación', onClick: () => router.push(`/clients/filesLanding/${uuid}`) }],
    null,
    [
      {
        text: 'Firmar operación',
        onClick: async () => {
          setActiveStep(6);
          await ClientService.approve_application(uuid);
          setSignedForm(true);
        },
      },
    ],
    null,
  ];

  let headerText;
  let text;

  if (step === 3) {
    if (client.expedient?.documents.length > 0) {
      // Maneja los diferentes estados de los documentos
      if (documentStatus === 'reviewing') {
        headerText = documentHeaderTexts[0];
        text = documentTexts[0];
      } else if (documentStatus === 'denied' && rejectedDocuments.length > 0) {
        headerText = documentHeaderTexts[1];

        text = documentTexts[1];
      } else {
        headerText = headerTexts[step_end - 1];
        text = texts[step_end - 1];
      }
    } else {
      headerText = headerTexts[step_end - 1];
      text = texts[step_end - 1];
    }
  } else {
    headerText = headerTexts[step_end - 1];
    text = texts[step_end - 1];
  }

  console.log('Test:', step_end, state, recommendation, client);

  return (
    <BannerLanding
      headerText={headerText}
      text={text}
      buttons={documentStatus === 'reviewing' ? null : buttons[step_end - 1]}
      color={documentStatus === 'denied' ? colors[1] : colors[step_end - 1]}
      align={aligns[step_end - 1]}
      textColor={documentStatus === 'denied' ? textColors[1] : textColors[step_end - 1]}
    />
  );
}

BannerSelector.propTypes = {
  recommendation: PropTypes.string,
  state: PropTypes.string,
  step: PropTypes.number,
  uuid: PropTypes.string,
  setActiveStep: PropTypes.func,
  setSignedForm: PropTypes.func,
  client: PropTypes.object,
  documentStatus: PropTypes.string,
  rejectedDocuments: PropTypes.object,
};
