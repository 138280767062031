/* eslint-disable no-else-return */
import { useMemo, useState, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
// utils
import { getUuid } from 'src/utils/get-uuid';
import ErrorView, { errorAlert } from 'src/utils/error-dialog';

// services
import ClientService from 'src/services/client';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData(unnax, unnaxAfter, files, refinance, aggregation, type) {
  const [client, setClient] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [closeFunction, setCloseFunction] = useState();
  const uuid = getUuid();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ClientService.get(uuid);
        setClient(response);
      } catch (error) {
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error inesperado al cargar la pagina',
          setErrorMsg,
          setErrorTitle,
          setOpenDialog
        );
        console.error('Error fetching client info:', error);
      }
    };

    fetchData();
    // reRender is here to ensure the useEffect executing when the component re-renders
  }, [uuid]);

  const data = useMemo(() => {
    const pathsNav = [
      {
        subheader: '',
        items: [
          { title: 'Posición global', path: paths.clients.landing(uuid), icon: ICONS.banking },
        ],
      },
    ];

    pathsNav[0].items.push({
      title: 'Mis documentos ',
      path: paths.clients.filesLanding(uuid),
      icon: ICONS.mail,
    });

    if (unnax || unnaxAfter) {
      pathsNav[0].items.push({
        title: 'Mi situación financiera',
        path: paths.clients.financial,
        icon: ICONS.analytics,
        disabled: !aggregation,
      });
    } else if (type === 'F') {
      pathsNav[0].items.push({
        title: 'Mi situación financiera',
        path: paths.clients.financial,
        icon: ICONS.analytics,
        disabled: !aggregation,
        tooltip: !aggregation
          ? 'Agrega tu cuenta bancaria y podrás obtener beneficios en la solución de tu deuda'
          : null,
      });
    }

    return pathsNav;
  }, [unnax, unnaxAfter, aggregation, type, uuid]);

  return data;
}
